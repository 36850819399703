
import {computed, defineComponent, ref} from "vue";
import {
    IonContent,
    IonPage,
    IonLabel,
    IonInput,
    IonItem, IonIcon
} from "@ionic/vue";
import {execute} from "@/mixins/LoadingMixin";
import FixedFooter from "@/components/FixedFooter.vue";
import {useStore} from "vuex";
import {createLanguage, getLanguage, updateLanguage} from "@/services/LanguageService";
import { Language } from "@/models/LanguageModel";
import {FooterButtonsBuilder} from "@/mixins/FooterButtonsBuilder";
import router from "@/router";
import {EMPTY_GUID} from "@/utils/GuidUtil";
import {caretDownOutline} from 'ionicons/icons';
import {openSelectModal} from "@/services/SelectModalService";
import {SelectOption} from "@/models/SelectOptionModel";
import {presentValidationAlert} from "@/services/Notify";
import {uuid4} from "@capacitor/core/dist/esm/util";

export default defineComponent({
    name: "Language",
    components: {
        IonContent,
        IonPage,
        IonLabel,
        IonInput,
        IonItem,
        IonIcon,
        FixedFooter
    },
    props: {
        id: {
            type: String,
            default: EMPTY_GUID
        }
    },
    async ionViewWillEnter() {
        await this.store.dispatch('app/setPageTitle', this.editing ? this.language.editLanguageInstance : this.language.addLanguageInstance);
        if (this.editing) {
            await execute(async () => {
                this.languageInstance = await getLanguage(this.languageId, false);
                this.statusOptions = [{
                    value: this.language.enabled,
                    text: this.language.enabled,
                    isChecked: this.languageInstance.enabled
                },{
                    value: this.language.disabled,
                    text: this.language.disabled,
                    isChecked: !this.languageInstance.enabled
                }];
            }, this.language.errors.gettingJourneySets);
        } else {
            this.statusOptions = [{
                value: this.language.enabled,
                text: this.language.enabled,
                isChecked: true
            },{
                value: this.language.disabled,
                text: this.language.disabled,
                isChecked: false
            }];
            this.languageInstance.enabled = true;
            this.languageInstance.language = '';
            this.languageInstance.code = '';
        }
    },
    setup(props: any) {
        const store = useStore();
        const language = computed(() => store.getters['app/language']);
        const languageId = computed(() => props.id);
        const languageInstance = ref({} as Language);
        const editing = computed(() => languageId.value !== EMPTY_GUID);
        const statusOptions = ref([] as SelectOption[]);
        
        const validate = (): boolean => {
            let message = '';
            if (languageInstance.value.language.trim() === '') {
                message = language.value.validation.enterLanguage;
            } else if (languageInstance.value.code.trim() === '') {
                message = language.value.validation.enterLanguageCode;
            } else if (languageInstance.value.code.trim().length !== 3) {
                message = language.value.validation.languageCodeCharacters;
            }
            
            if (message) {
                presentValidationAlert(message);
                return false;
            }
            return true;
        }
        
        const save = async () => {
            if (validate()) {
                await execute(async () => {
                    if (editing.value) {
                        await updateLanguage(languageInstance.value);
                    } else {
                        languageInstance.value.id = uuid4();
                        await createLanguage(languageInstance.value);
                    }
                    await router.replace({ name: 'languages' });
                }, language.value.errors.savingLanguage);
            }
        }

        const pickStatus = async () => {
            const statusSelect = await openSelectModal(language.value.instanceStatus, 'radio', statusOptions.value,false);
            statusSelect.onWillDismiss().then(({data}: any) => {
                const selection = data.find((option: SelectOption) => option.isChecked)?.text;
                languageInstance.value.enabled = selection?.toLowerCase() === 'enabled';
            });
        }
        
        return {
            store,
            editing,
            language,
            languageInstance,
            languageId,
            caretDownOutline,
            pickStatus,
            statusOptions,
            footerButtons: computed(() => 
                new FooterButtonsBuilder()
                .addClearButton(false, () => router.back(), language.value.cancel)
                .addPrimaryButton(false, async () => await save(), editing.value ? language.value.saveEdits : language.value.addLanguage)
                .create()
            )
        }
    }
});
